<template>
    <div class="loading" v-show="load" @touchmove.prevent>
     
    </div>
</template>

<script>
    export default {
            data(){
                return{
                    load:0
                }
            }
    }
</script>

<style lang="less" scoped>
.loading{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color:rgba(255,255,255,0);
    z-index: 99999999999;
}
</style>