import https from '@/router/https'

// 获取分类列表
export function getClassification(param) {
	return https.fetchGet('/createrapp/mallcategoryconfig/page', param)
}

// 获取商品列表
export function getProduct(param) {
	return https.fetchGet('/createrapp/makerproduct/page', param)
}

// 获取所有模块配置
export function getConfigs(param) {
	return https.fetchGet('/createrapp/furnishconfig/page', param)
}

// 应用
export function storeUse(param) {
	return https.fetchPost('/createrapp/storeBuild/v2/storeCompleteUse', param)
}

// 获取预览
export function getStoreInfo(param) {
	return https.fetchGet('/createrapp/storeBuild/v2/storeCompleteInfo', param)
}

// 金币解锁模块/分类/商品
export function unlock(param) {
	return https.fetchPost('/createrapp/storeBuild/goldConsume', param)
}

// 获取已解锁模块配置
export function getUnlockedConfigs(param) {
	return https.fetchGet('/createrapp/storeunlockrecord/shop/all', param)
}

// 获取已解锁类目
export function getUnlockedCates(param) {
	return https.fetchGet('/createrapp/storeunlockrecord/shop/category', param)
}

// 获取已解锁商品
export function getUnlockedGoods(param) {
	return https.fetchGet('/createrapp/storeunlockrecord/shop/product', param)
}

// 获取解锁橱窗栏位
export function getShowcase(param) {
	return https.fetchGet('/createrapp/storeBuild/showcaseConfig', param)
}

// 金币解锁橱窗栏位
export function unlockField(param) {
	return https.fetchPut('/createrapp/storeBuild/unlockField', param)
}
