import Vue from "vue";
import Vuex from "vuex";

import fitment from "./fitment";
import shopCar from "./shopCar";
import tsMall from "./tsMall";
import miliOrder from "./miliOrder"
import zhuanpan from "./zhuanpan"
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    LOADING: false,
    session: "",
    devicetype: "IMEI",
    deviceValue: "1",
    shareUrl: "",
    token: null,
    empower: "0",
    shareOpenUrl: "http://localhost:8080/#",
    sharePicUrl: [],
    shareContent: "",
    shareTitle: "",
    platformId: "1",
    makeup: "0",
    price: "0",
    oldPrice: "0",
    statusActive: -1,
    fansActive: 0,
    userDate: null,
    routerArr: [], //路由历史记录
    isIOS: false,
    isAndroid: false,
    liveRecordsId: ''
  },
  mutations: {
    addRouter(state, value) {
      if (
        state.routerArr.length > 1 &&
        value.path == state.routerArr[state.routerArr.length - 2].path
      ) {
        state.routerArr.pop();

        return;
      }
      state.routerArr.push(value);
    },
    delRouter(state) {
      state.routerArr.pop();
    },
    setSession(state, value) {
      state.session = value;
      state.token = value;
      window.sessionStorage.setItem("third-session", value);
    },
    setDevie(state, value, value1) {
      state.devicetype = value;
      state.deviceValue = value1;
    },
    setToken(state, value) {
      state.token = value;
    },
    //分享页面，分享商品的URL
    setUrl(state, value) {
      state.shareUrl = value;
    },
    setEmpower(state, value) {
      state.empower = value;
    },
    //分享页面，分享商品的信息
    setShareData(state, obj) {
      state.shareOpenUrl = obj.valueOpenUrl;
      state.sharePicUrl = obj.valuePicUrl;
      state.shareContent = obj.valueContent;
      state.shareTitle = obj.valueTiltle;
      state.platformId = obj.valueFormId;
      state.makeup = obj.valueMakeup;
      state.price = obj.valuePrice;
      state.oldPrice = obj.valueoldPrice;
    },
    setActive(state, value) {
      state.statusActive = value;
    },
    setfansActive(state, value) {
      state.fansActive = value;
    },
    setUserInfo(state, value) {
      state.userDate = value;
    },

    setIOS(state, value) {
      state.isIOS = value;
    },
    setAndroid(state, value) {
      state.isAndroid = value;
    },

    set_liveRecordsId (state, liveId) {
      state.liveRecordsId = liveId
    }
  },
  actions: {},
  modules: {
    fitment,
    shopCar,
    tsMall,
    miliOrder,
    zhuanpan
  },
  getters: {
    liveRecordsId (state) {
      return state.liveRecordsId
    }
  }
});

export default store;
