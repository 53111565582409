<template>
  <div class="tc" @touchmove.prevent v-if="alertShow">
    <div class="box">
      <div class="title">是否在"浏览器"中打开</div>
      <div class="btn">
        <div class="right" @click="alertShow = false">取消</div>
        <a class="right" :href="openUrl" @click="cancel"> 打开 </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openUrl: "",
      alertShow: false,
    };
  },
  methods: {
    cancel() {
      let id = setTimeout(() => {
        // navigator.userAgent.includes('Mac') &&  (window.location='https://apps.apple.com/cn/app/%E4%B8%87%E6%B0%91%E5%95%86%E5%9F%8E/id1602170624')
        // !navigator.userAgent.includes('Mac') &&  (window.location='https://apps.apple.com/cn/app/%E4%B8%87%E6%B0%91%E5%95%86%E5%9F%8E/id1602170624')
        if (navigator.userAgent.includes("Mac")) {
          window.location = "https://apps.apple.com/cn/app/id1638693950";
        }
        if (!navigator.userAgent.includes("Mac")) {
          this.alertShow = false;
          this.$dialog
            .confirm({
              title: "提示",
              message: "即将下载Win生活安装包",
            })
            .then(() => {
              window.location.href = "https://www.pgyer.com/eOTh";
            })
            .catch(() => {
              this.alertShow = true;
            });
        }
      }, 2000);

      document.addEventListener("visibilitychange", () => {
        if (document.hidden || document.webkitHidden) clearInterval(id);
      });
      document.addEventListener("qbrowserVisibilityChange", (e) => {
        if (e.hidden || document.webkitHidden) {
          clearInterval(id);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tc {
  background-color: #bbb;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFangSC-Regular, PingFang SC;

  z-index: 99999;
  font-size: 12px;
  .box {
    background-color: white;
    width: 90%;
    border-radius: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    box-shadow: 5px 5px 10px 10px #999;
    .title {
      padding-bottom: 20px;
    }
    .btn {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      .right {
        margin-left: 20px;
        color: rgb(51, 119, 237);
      }
      a {
        color: rgb(59, 117, 248);
        font-size: 14px;
      }
    }
  }
}
</style>