import loading from './loading'

const $loading = {
  install: (Vue) => {
    const Profile = Vue.extend(loading)
    const ProfileComponent = new Profile()
    const tpl = ProfileComponent.$mount().$el
    document.body.appendChild(tpl)
    Vue.prototype.$GeShowLoading = () => {
      ProfileComponent.isShow = true
    },
    Vue.prototype.$GeHideLoading = () => {
      ProfileComponent.isShow = false
    }
  }
}

export default $loading