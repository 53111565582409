<template>
  <div id="app" >
    <keep-alive  include="keep,coll,makerCenter,afterSale,classification,beiDaClassDetail" >
    
<!-- include=""
    <router-view   />

    <router-view  /> -->

    <router-view   />
   </keep-alive>
  </div>
</template> 
<script>


export default {
   data() {
    return {};
  },
  created() {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    this.$store.commit('setIOS', isIOS)
    this.$store.commit('setAndroid', isAndroid)
    
    window.receiveSession = this.receiveSession
    try{ 
      window.webkit.messageHandlers.getToken.postMessage("")
    }catch{ 

      //
      
    }
    try{ 
      window.android.getToken()
    }catch{ 
      //
    }

    

  },
  mounted() {},
  methods: {
    receiveSession(data){ 

      this.$store.commit('setSession',data)
    }
  },
  watch: {},
  computed: {},
}
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100vh;
  background: #f6f6f6;
  background-color: white;
 -webkit-overflow-scrolling: touch;
}
#app::-webkit-scrollbar{
  display: none;
}
.main::-webkit-scrollbar{ 
  display: none;
}
img,div,span,p,video{ 
  -webkit-touch-callout: none;
    user-select:none;
    -webkit-user-select: none;
    -webkit-touch-callout:none; /*系统默认菜单被禁用*/

-webkit-user-select:none; /*webkit浏览器*/

-khtml-user-select:none; /*早期浏览器*/

-moz-user-select:none;/*火狐*/

-ms-user-select:none; /*IE10*/

user-select:none;
    
}


</style>