export default {
  namespaced: true,
  state: {
    shangpinInfo: {
      name: '', // 商品名称
      ricePrice: '', // 规格价格
      salesPrice: '', // 原价
      goodsId: '',
      skuId: '',
      count: '',
    }
  },

  mutations: {
    set_shangpinInfo (state, data) {
      state.shangpinInfo = data
    }
  }
}