import Loading from './index.vue'

const loadingpage = {
    install(Vue) {
        const LoadingClass = Vue.extend(Loading)
        const loadingvm = new LoadingClass()
        document.body.appendChild(loadingvm.$mount().$el)
        Vue.prototype.$showLoding = () => {
            loadingvm.isshow = ++loadingvm.load
            // loadingvm.isshow = true
            console.log(loadingvm.isshow, '葛先波')
        },
            Vue.prototype.$hideLoading = () => {
                loadingvm.isshow = --loadingvm.load
                // loadingvm.isshow = false
            }
    }
}
export default loadingpage