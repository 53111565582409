export default {
  namespaced: true,

  state: {
    list_pan: false,
    rewardId: ''
  },

  mutations: {
    set_list_pan (state, Boolean) {
      state.list_pan = Boolean
    },

    set_rewardId (state, rewardId) {
      state.rewardId = rewardId
    }
  }
}