import { getConfigs, getStoreInfo, getUnlockedConfigs, getUnlockedCates, getUnlockedGoods, getShowcase } from '@/api/fitment'

const fitment = {
	state: {
		list: [], //装修内容

		storeType: '1', // 头部样式
		// 头部数据
		headerContent: {
			signUrl: null,
			logoUrl: null,
			storeName: null,
			storeDescribe: null
		},
		first: true,

		editList: [], //编辑中的装修内容

		chosenType: null, // 选择商品或类目 类型
		chosenIndex: null, // 选择商品或类目 索引
		chosenValue: null, // 选择商品或类目 值

		// 装修模块
		modules: [],

		// 已解锁类目
		unlockedCates: [],
		// 已解锁商品
		unlockedGoods: [],

		showCaseNum: 0, // 商品栏橱窗数量
		showCaseGold: 0 // 商品栏橱窗解锁金币
	},
	getters: {
		// 可用橱窗栏位
		showCaseUsable(state) {
			let num = state.showCaseNum
			state.list.map(x => {
				if (x.modulesType == 1) {
					num -= x.childList.length
				}
			})
			return num
		},
    // 商品栏已选中商品
		goodsCaseUsable(state) {
			let arr = []
			state.list.map(x => {
				if (x.modulesType == 1) {
					x.childList.map(i => {
						let flag = true
						arr.map(n => {
							if (n.id == i.id) {
								flag = false
							}
						})
						flag ? arr.push(i) : null
					})
				}
			})
			return arr
		}
	},
	mutations: {
		// 设置顶部样式类型
		setStoreType(state, str) {
			state.storeType = str
		},
		// 设置顶部样式内容
		setHeaderContent(state, obj) {
			state.headerContent = obj
		},
		// 设置模块列表
		setList(state, obj) {
			state.list = obj
		},
		listSplice(state, { index, item }) {
			if (item) {
				// 插入
				state.list.splice(index, 0, { modulesType: item[0], styleType: item[1], childList: [] })
			} else {
				// 删除
				state.list.splice(index, 1)
			}
		},
		// 上移
		listChange(state, index) {
			let obj = state.list[index - 1]
			state.list[index - 1] = state.list[index]
			state.list[index] = obj
		},
		// 设置列表项 值
		setListValue(state, { index, value }) {
			state.list[index].childList = value
		},
		// 设置编辑列表
		setEditList(state, arr) {
			state.editList = JSON.parse(JSON.stringify(arr))
		},
		// 选择商品或分类
		chooseLink(state, { type, index, value }) {
			state.chosenType = type
			state.chosenIndex = index
			state.chosenValue = value
		},
		// 设置装修模块列表
		setModules(state, arr) {
			state.modules = arr
		},
		// 设置已解锁类目列表
		setUnlockedCates(state, arr) {
			state.unlockedCates = arr
		},
		// 设置已解锁商品列表
		setUnlockedGoods(state, arr) {
			state.unlockedGoods = arr
		},
		// 设置已解锁橱窗栏位及金币
		setShowCase(state, { num, gold }) {
			state.showCaseNum = num
			state.showCaseGold = gold
		},
		setFirst(state) {
			state.first = false
		}
	},
	actions: {
		// 获取模块配置
		getConfigs({ commit }) {
			return new Promise((resolve, reject) => {
				getConfigs({ size: 20 })
					.then(res => {
						const result = res.data.data
						let arr = result.records.filter(x => {
							x.lock = true
							return x.id === '6' || x.id === '7' || x.id === '9' || x.id === '11' || x.id === '12' || x.id === '13' || x.id === '14' || x.id === '18'
						})
						arr = arr.sort((obj1, obj2) => {
							var val1 = +obj1.id
							var val2 = +obj2.id
							if (val1 < val2) {
								return -1
							} else if (val1 > val2) {
								return 1
							} else {
								return 0
							}
						})
						commit('setModules', arr)
						resolve(res)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		// 获取已解锁模块配置
		getUnlockedConfigs({ state, commit }) {
			return new Promise((resolve, reject) => {
				getUnlockedConfigs()
					.then(res => {
						const result = res.data.data
						let arr = state.modules
						arr.map(x => {
							result.map(i => {
								if (i.unlockId == x.id) {
									x.lock = false
								}
							})
						})
						commit('setModules', arr)
						resolve(res)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		// 获取已解锁类目
		getUnlockedCates({ commit }) {
			return new Promise((resolve, reject) => {
				getUnlockedCates()
					.then(res => {
						commit('setUnlockedCates', res.data.data)
						resolve(res)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		// 获取已解锁商品
		getUnlockedGoods({ commit }) {
			return new Promise((resolve, reject) => {
				getUnlockedGoods()
					.then(res => {
						commit('setUnlockedGoods', res.data.data)
						resolve(res)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		// 获取已解锁橱窗栏位
		getShowcase({ commit }) {
			return new Promise((resolve, reject) => {
				getShowcase()
					.then(res => {
						const result = res.data.data
						commit('setShowCase', { num: result.initNum, gold: result.addFee })
						resolve(res)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		// 获取预览
		getStoreInfo({ commit }) {
			return new Promise((resolve, reject) => {
				getStoreInfo()
					.then(res => {
						const result = res.data.data
						if (result.modulesVO) {
							result.modulesVO.map(x => {
								if (x.modulesType === '1') {
									x.childList.map(i => {
										i.id = i.productId
									})
								} else if (x.modulesType === '2' || x.modulesType === '3') {
									x.childList.map(i => {
										if (i.navigationType === '1' || i.advertType === '1') {
											i.name = i.subject
										} else if (i.navigationType === '2' || i.advertType === '2') {
											i.name = i.categoryName
										}
										i.imageUrl = [{ url: i.picUrl }]
									})
								}
							})
						}
						commit('setList', result.modulesVO ? result.modulesVO : [])
						commit('setStoreType', result.shopBasicVO.storeType ? result.shopBasicVO.storeType : '1')
						commit('setHeaderContent', {
							signUrl: result.shopBasicVO.signUrl,
							logoUrl: result.shopBasicVO.logoUrl,
							storeName: result.shopBasicVO.storeName,
							storeDescribe: result.shopBasicVO.storeDescribe
						})
						commit('setFirst')
						resolve(res)
					})
					.catch(error => {
						reject(error)
					})
			})
		}
	}
}
export default fitment
