import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import './assets/icon-fenxiang/iconfont.css'
import './assets/icon-login/iconfont.css'
import './assets/cameraIcon/iconfont.css'

import {
	Toast, AddressEdit, Sku, CouponCell, CouponList, Card, ShareSheet, Rate, Badge, Tab, Tabs, ActionSheet,
	PullRefresh, List, Divider, Loading, Swipe, SwipeItem, Lazyload, Icon, Step, Steps, Col, Row, Slider, Uploader, Popover, Skeleton, CountDown, Popup, Image as VanImage, Button, Stepper, SubmitBar
} from 'vant'
import { areaList } from '@vant/area-data'
// 引入axios，并加到原型链中
import axios from 'axios'

Vue.prototype.$axios = axios
import QS from 'qs'
Vue.prototype.qs = QS
// 点击复制插件
import Clipboard from 'clipboard'
Vue.prototype.Clipboard = Clipboard
import Vant from 'vant'
import 'vant/lib/index.css'
import './styles/global.css'
import loading from './Loading/loading'
import WMAerlt from './views/WMAerlt/index'
import { roadNav, setRem, goBack, shareInfo, getUser, fetchTime, hotelPay, openPay, isIOS, openApp, back, cCT, deleteOrder, jump, contact } from "./common/index"
import { collection } from "./api/sign/index"
import geLoading from './plugins'
import VueLuckyCanvas from '@lucky-canvas/vue'

Vue.use(VueLuckyCanvas)
Vue.use(geLoading)
Vue.use(CountDown)
Vue.use(Skeleton)
Vue.use(CouponCell)
Vue.use(CouponList)
Vue.use(Sku)
Vue.use(Vant)
Vue.use(Toast)
Vue.use(areaList)
Vue.use(AddressEdit)
Vue.use(Card)
Vue.use(ShareSheet)
Vue.use(Rate)
Vue.use(Badge)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(ActionSheet)
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Divider)
Vue.use(Loading)
Vue.use(loading)
Vue.use(WMAerlt)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload, { lazyComponent: true, })
Vue.use(Icon)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Col)
Vue.use(Row)
Vue.use(Slider)
Vue.use(Uploader)
Vue.use(Popover)
Vue.use(Popup)
Vue.use(VanImage)
Vue.use(Button)
Vue.use(Stepper)
Vue.use(SubmitBar)
Vue.config.productionTip = false
Vue.prototype.roadNav = roadNav //导航 调用原生导航方法 { longitude,latitude,目的地名字 }
Vue.prototype.setRem = setRem  //设置根字体
Vue.prototype.$goBack = goBack //返回到上一页  已做兼容 可以返回原生和h5的上一页
Vue.prototype.shareInfo = shareInfo // 原生分享接口 需要传一个对象  {title,picUrl,platformId,openUrl,content} 要按顺序传入platformId：分享的app 0微信1 朋友圈 2 QQ
//
Vue.prototype.Clipboard = Clipboard
Vue.prototype.qs = QS
Vue.prototype.collection = collection
Vue.prototype.getUser = getUser //获取用户信息 可以直接使用 getUser()
Vue.prototype.fetchTime = fetchTime
Vue.prototype.hotelPay = hotelPay //酒店支付接口 传入订单id,支付方式:1微信2支付宝,传入订单金额
Vue.prototype.isIOS = isIOS //判断设备类型
Vue.prototype.openPay = openPay //调用原生支付能力 
Vue.prototype.openApp = openApp  //h5打开 app 方法
Vue.prototype.back = back
Vue.prototype.cCT = cCT //取消收藏方法   有.then回调  传一个收藏id
Vue.prototype.deleteOrder = deleteOrder //删除订单的方法 type： 订单类型 1自营-同城-家政 2电影票 3酒店 ； id：订单id
Vue.prototype.$jump = jump
Vue.prototype.$contact = contact
Vue.prototype.$Toast = Toast
Vue.config.devtools = true


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
