import {fetchGet,fetchPost,fetchDelete}from '../../router/https.js'
function getCollection(data){
    return fetchGet('mallapp/usercollect/page',data)
}
function delColl(data){
    return fetchPost('/mallapp/usercollect/del',data)
}

function cancelColl(data){ 
    return fetchDelete('mallapp/usercollect/'+data)
}
export {

    //获取收藏列表
    getCollection,

    //删除收藏
    delColl,
    //取消收藏
    cancelColl
}