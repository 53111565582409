import {fetchPost,fetchDelete} from "../../router/https"
function wxPay(data){
    return fetchPost('mallapp/hotelorderinfo/hotelUnifiedOrder',data)
}
function aliPay(data){
    return fetchPost('mallapp/hotelorderinfo/appAlipay',data)
}
function hotelDel(data){
    return fetchDelete('/mallapp/hotelorderinfo/'+data)
}
export{
    wxPay,
    aliPay,
    hotelDel
}