export default {
  namespaced: true,
  state: {
    data: [],
    remark: ''
  },

  mutations: {
    set_data (state, data) {
      data.forEach(x => {
        let freightPrice = 0
        const chargeType = x.freightTemplat[0].chargeType // 运费计算方式
        const data = x.freightTemplat[0] // 运费模板
        const weight = Number(x.goodsSku.weight) * x.quantity // 重量
        const volume = Number( x.goodsSku.volume) * x.quantity // 体积
        const totalPieceWeightVolume = chargeType == '1' ? x.quantity : chargeType == '2' ? weight : chargeType == '3' ? volume : null
        console.log(totalPieceWeightVolume, '重量')
        
        if (chargeType == '') {
          freightPrice = 0
        } else if (chargeType == '1' || chargeType == '2' || chargeType == '3') { // 按件数
          if ((totalPieceWeightVolume < data.firstNum)) { // || (data.firstNum == 0 && data.continueNum == 0) 
            freightPrice = 0
          } else if (totalPieceWeightVolume == data.firstNum) {
            freightPrice = data.firstFreight
          } else if (totalPieceWeightVolume > data.firstNum && (totalPieceWeightVolume - data.firstNum) < data.continueNum ) {
            freightPrice = data.firstFreight
          } 
          else if ((totalPieceWeightVolume - data.firstNum) >= data.continueNum) {
            const xvJianShuo = totalPieceWeightVolume - data.firstNum
            const continueNum = data.continueNum
            freightPrice = Math.floor(xvJianShuo / continueNum) * data.continueFreight + data.firstFreight;
          }
        }
        // if ((x.quantity < data.firstNum)) { // || (data.firstNum == 0 && data.continueNum == 0) 
        //   freightPrice = 0
        // } else if (x.quantity == data.firstNum) {
        //   freightPrice = data.firstFreight
        // } else if ( x.quantity > data.firstNum && (x.quantity - data.firstNum) < data.continueNum ) {
        //   freightPrice = data.firstFreight
        // } 
        // else if ((x.quantity - data.firstNum) >= data.continueNum) {
        //   const xvJianShuo = x .quantity - data.firstNum
        //   const continueNum = data.continueNum
        //   freightPrice = Math.floor(xvJianShuo / continueNum) * data.continueFreight + data.firstFreight;
        // }

        x.freightPrice = freightPrice
        console.log(x.freightPrice, '运费， 星期二')
      })
      state.data = JSON.parse(JSON.stringify(data))
    },

    set_remark (state, remark) {
      state.remark = remark
    }
  },

  getters: {
    totalPrice (state) {
      let totalPrice = 0
      state.data.forEach(x => {
        totalPrice = totalPrice + (x.addPrice * x.quantity)
      })
      return totalPrice.toFixed(2)
    },

    quantity (state) {
      let quantity = 0
      state.data.forEach(x => {
        quantity = quantity + x.quantity
      })
      return quantity
    },

    yunfei (state) {
      let yunfei = 0
      state.data.forEach(x => {
        yunfei = yunfei + x.freightPrice
      })
      return yunfei
    },

    skus (state) {
      let skus = state.data.map(x => ({
        spuId: x.spuId,
        skuId: x.skuId,
        quantity: x.quantity,
        paymentPrice: x.paymentPrice,
        freightPrice: x.freightPrice
      }))
      return skus
    }
  }
}