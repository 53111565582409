<template>
  <div class="mask" v-show="isShow" @touchmove.prevent>
    <div class="loading"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isShow: false
    }
  },

  methods: {
    mousewheel (e) {
      e.preventDefault()
    }
  }
}
</script>

<style lang="less" scoped>
@keyframes rotate {
  0% {
    background: url(./Image/loading_00.png) no-repeat;
    background-size: cover;
  }
  1.66% {
    background: url(./Image/loading_01.png) no-repeat center;
    background-size: cover;
  }
  3.32% {
    background: url(./Image/loading_02.png) no-repeat center;
    background-size: cover;
  }
  4.98% {
    background: url(./Image/loading_03.png) no-repeat center;
    background-size: cover;
  }
  6.64% {
    background: url(./Image/loading_04.png) no-repeat center;
    background-size: cover;
  }
  8.3% {
    background: url(./Image/loading_05.png) no-repeat center;
    background-size: cover;
  }
  9.96% {
    background: url(./Image/loading_06.png) no-repeat center;
    background-size: cover;
  }
  11.62% {
    background: url(./Image/loading_07.png) no-repeat center;
    background-size: cover;
  }
  13.28% {
    background: url(./Image/loading_08.png) no-repeat center;
    background-size: cover;
  }
  14.94% {
    background: url(./Image/loading_09.png) no-repeat center;
    background-size: cover;
  }
  16.6% {
    background: url(./Image/loading_10.png) no-repeat center;
    background-size: cover;
  }
  18.26% {
    background: url(./Image/loading_11.png) no-repeat center;
    background-size: cover;
  }
  19.92% {
    background: url(./Image/loading_12.png) no-repeat center;
    background-size: cover;
  }
  21.58% {
    background: url(./Image/loading_13.png) no-repeat center;
    background-size: cover;
  }
  23.24% {
    background: url(./Image/loading_14.png) no-repeat center;
    background-size: cover;
  }
  24.9% {
    background: url(./Image/loading_15.png) no-repeat center;
    background-size: cover;
  }
  26.56% {
    background: url(./Image/loading_16.png) no-repeat center;
    background-size: cover;
  }
  28.22% {
    background: url(./Image/loading_17.png) no-repeat center;
    background-size: cover;
  }
  29.88% {
    background: url(./Image/loading_18.png) no-repeat center;
    background-size: cover;
  }
  31.54% {
    background: url(./Image/loading_19.png) no-repeat center;
    background-size: cover;
  }
  33.2% {
    background: url(./Image/loading_20.png) no-repeat center;
    background-size: cover;
  }
  34.86% {
    background: url(./Image/loading_21.png) no-repeat center;
    background-size: cover;
  }
  36.52% {
    background: url(./Image/loading_22.png) no-repeat center;
    background-size: cover;
  }
  38.18% {
    background: url(./Image/loading_23.png) no-repeat center;
    background-size: cover;
  }
  39.84% {
    background: url(./Image/loading_24.png) no-repeat center;
    background-size: cover;
  }
  41.5% {
    background: url(./Image/loading_25.png) no-repeat center;
    background-size: cover;
  }
  43.16% {
    background: url(./Image/loading_26.png) no-repeat center;
    background-size: cover;
  }
  44.82% {
    background: url(./Image/loading_27.png) no-repeat center;
    background-size: cover;
  }
  46.48% {
    background: url(./Image/loading_28.png) no-repeat center;
    background-size: cover;
  }
  48.14% {
    background: url(./Image/loading_29.png) no-repeat center;
    background-size: cover;
  }
  49.8% {
    background: url(./Image/loading_30.png) no-repeat center;
    background-size: cover;
  }
  51.46% {
    background: url(./Image/loading_31.png) no-repeat center;
    background-size: cover;
  }
  53.12% {
    background: url(./Image/loading_32.png) no-repeat center;
    background-size: cover;
  }
  54.78% {
    background: url(./Image/loading_33.png) no-repeat center;
    background-size: cover;
  }
  56.44% {
    background: url(./Image/loading_34.png) no-repeat center;
    background-size: cover;
  }
  58.1% {
    background: url(./Image/loading_35.png) no-repeat center;
    background-size: cover;
  }
  59.76% {
    background: url(./Image/loading_36.png) no-repeat center;
    background-size: cover;
  }
  61.42% {
    background: url(./Image/loading_37.png) no-repeat center;
    background-size: cover;
  }
  63.08% {
    background: url(./Image/loading_38.png) no-repeat center;
    background-size: cover;
  }
  64.74% {
    background: url(./Image/loading_39.png) no-repeat center;
    background-size: cover;
  }
  66.4% {
    background: url(./Image/loading_40.png) no-repeat center;
    background-size: cover;
  }
  68.06% {
    background: url(./Image/loading_41.png) no-repeat center;
    background-size: cover;
  }
  69.72% {
    background: url(./Image/loading_42.png) no-repeat center;
    background-size: cover;
  }
  71.38% {
    background: url(./Image/loading_43.png) no-repeat center;
    background-size: cover;
  }
  73.04% {
    background: url(./Image/loading_44.png) no-repeat center;
    background-size: cover;
  }
  74.7% {
    background: url(./Image/loading_45.png) no-repeat center;
    background-size: cover;
  }
  76.36% {
    background: url(./Image/loading_46.png) no-repeat center;
    background-size: cover;
  }
  78.02% {
    background: url(./Image/loading_47.png) no-repeat center;
    background-size: cover;
  }
  79.68% {
    background: url(./Image/loading_48.png) no-repeat center;
    background-size: cover;
  }
  81.34% {
    background: url(./Image/loading_49.png) no-repeat center;
    background-size: cover;
  }
  83% {
    background: url(./Image/loading_50.png) no-repeat center;
    background-size: cover;
  }
  84.66% {
    background: url(./Image/loading_51.png) no-repeat center;
    background-size: cover;
  }
  86.32% {
    background: url(./Image/loading_52.png) no-repeat center;
    background-size: cover;
  }
  87.98% {
    background: url(./Image/loading_53.png) no-repeat center;
    background-size: cover;
  }
  89.64% {
    background: url(./Image/loading_54.png) no-repeat center;
    background-size: cover;
  }
  91.3% {
    background: url(./Image/loading_55.png) no-repeat center;
    background-size: cover;
  }
  92.96% {
    background: url(./Image/loading_56.png) no-repeat center;
    background-size: cover;
  }
  94.62% {
    background: url(./Image/loading_57.png) no-repeat center;
    background-size: cover;
  }
  96.28% {
    background: url(./Image/loading_58.png) no-repeat center;
    background-size: cover;
  }
  97.94% {
    background: url(./Image/loading_59.png) no-repeat center;
    background-size: cover;
  }
  99.6% {
    background: url(./Image/loading_00.png) no-repeat center;
    background-size: cover;
  }
  100% {
    background: url(./Image/loading_01.png) no-repeat center;
    background-size: cover;
  }
}
.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  width: 100px;
  height: 50px;
  animation: rotate 1s infinite;
}
</style>