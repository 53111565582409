import WMAerlt from './index.vue'

const alerting = {
    install(Vue) {
        const Alert = Vue.extend(WMAerlt)
        const Alertvm = new Alert()
        document.body.appendChild(Alertvm.$mount().$el)
        Vue.prototype.$showAlert = () => {
            Alertvm.alertShow=true
        }
        Vue.prototype.$sendData = (data) => {
            Alertvm.openUrl=data
        }
           
    }
}
export default alerting