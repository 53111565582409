import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/itemDetail",
    name: "ItemDetail",
    component: () => import("../views/itemDetail"),
  },
  {
    path: "/aad",
    name: "Aad",
    component: () => import("../views/aad"),
  }, //
  {
    path: "/shopDetail",
    name: "ShopDetail",
    component: () => import("../views/shopDetail"),
  },
  {
    path: "/cart2",
    name: "Cart2",
    component: () => import("../views/cart2"),
  },
  {
    path: "/applySale",
    name: "ApplySale",
    component: () => import("../views/applySale"),
  },
  {
    path: "/applyReturn",
    name: "ApplyReturn",
    component: () => import("../views/applyReturn"),
  },
  {
    path: "/returnGoodsDetail",
    name: "ReturnGoodsDetail",
    component: () => import("../views/returnGoodsDetail"),
  },
  {
    path: "/returnMoneyDetail",
    name: "ReturnMoneyDetail",
    component: () => import("../views/returnMoneyDetail"),
  },
  // 物流信息
  {
    path: "/writeLog",
    name: "WriteLog",
    component: () => import("../views/writeLog"),
  },
  // 防疫情指南
  {
    path: "/preventEpi",
    name: "PreventEpi",
    component: () => import("../views/preventEpi"),
  },
  // 周公解梦
  {
    path: "/solveDream",
    name: "SolveDream",
    component: () => import("../views/solveDream"),
  },
  // 查看更多
  {
    path: "/solveDreamKind",
    name: "SolveDreamKind",
    component: () => import("../views/solveDreamKind"),
  },
  {
    path: "/comment",
    name: "Comment",
    component: () => import("../views/comment"),
  },
  {
    path: "/demo",
    name: "Demo",
    component: () => import("../views/demo"),
  },
  {
    path: "/order",
    name: "Order",
    component: () => import("../views/order"),
  },
  {
    // 订单详情
    path: "/orders",
    name: "Orders",
    component: () => import("../views/orders"),
  },
  {
    // 图文广告详情
    path: "/advertisingdetails",
    name: "advertisingdetails",
    component: () => import("../views/advertisingdetails"),
  },
  {
    path: "/fansmanagement",
    name: "Fansmanagement",
    component: () => import("../views/fansmanagement"),
  },
  {
    path: "/fansorder",
    name: "Fansorder",
    component: () => import("../views/fansorder"),
  },
  {
    path: "/signin",
    name: "Signin",
    component: () => import("../views/signin"),
  },
  // 签到
  {
    path: "/sign",
    name: "Sign",
    component: () => import("../views/sign"),
  },
  {
    // 邀请好友
    path: "/people",
    name: "People",
    component: () => import("../views/people"),
  },
  {
    // 店铺详情
    path: "/detailsPage",
    name: "DetailsPage",
    component: () => import("../views/detailsPage"),
  },

  {
    path: "/goodsclassify",
    name: "Goodsclassify",
    component: () => import("../views/goodsclassify"),
  },
  {
    path: "/goodsclassifydetail",
    name: "Goodsclassifydetail",
    component: () => import("../views/goodsclassifydetail"),
  },
  {
    path: "/goodsclassifydetailList",
    name: "goodsclassifydetailList",
    component: () => import("../views/goodsclassifydetailList"),
  },
  // 商品详情
  {
    path: "/goodsdetails",
    name: "Goodsdetails",
    component: () => import("../views/goodsdetails"),
  },
  // 黄金数据
  {
    path: "/golddata",
    name: "Golddata",
    component: () => import("../views/golddata"),
  },
  {
    path: "/golddatadetail",
    name: "Golddatadetail",
    component: () => import("../views/golddatadetail"),
  },
  {
    path: "/constellationdetail",
    name: "Constellationdetail",
    component: () => import("../views/constellationdetail"),
  },
  {
    path: "/constellation",
    name: "Constellation",
    component: () => import("../views/constellation"),
  },
  {
    path: "/weatherforecast",
    name: "Weatherforecast",
    component: () => import("../views/weatherforecast"),
  },
  {
    path: "/collect",
    name: "Collect",
    component: () => import("../views/collect"),
  },
  {
    path: "/commentshop",
    name: "Commentshop",
    component: () => import("../views/commentshop"),
  },
  {
    path: "/orderManagement",
    name: "OrderManagement",
    component: () => import("../views/orderManagement"),
  },
  {
    path: "/goodshare",
    name: "goodshare",
    component: () => import("../views/goodshare"),
  },
  {
    path: "/userAgreement",
    name: "用户协议",
    component: () => import("../views/userAgreement"),
  },
  {
    path: "/privacyPolicy",
    name: "隐私政策",
    component: () => import("../views/privacyPolicy"),
  },

  {
    path: "/userAgreementLife",
    name: "用户协议2",
    component: () => import("../views/userAgreement/userAgreementLife"),
  },
  {
    path: "/privacyPolicyLife",
    name: "隐私政策2",
    component: () => import("../views/privacyPolicy/privacyPolicyLife"),
  },
  {
    path: "/noviceCourse",
    name: "新手教程",
    component: () => import("../views/noviceCourse"),
  },
  {
    path: "/purchase",
    name: "使用抵用券",
    component: () => import("../views/purchase"),
  },
  {
    path: "/placeOrder",
    name: "买卖提交订单",
    component: () => import("../views/placeOrder"),
  },
  {
    path: "/orderDetails",
    name: "订单详情",
    component: () => import("../views/orderDetails"),
  },
  {
    path: "/lifeDetails",
    name: "生活详情",
    component: () => import("../views/lifeDetails"),
  },
  {
    path: "/Settinger",
    name: "小店设置",
    component: () => import("../views/Settinger"),
  },
  {
    path: "/Merchant",
    name: "商家管理中心",
    component: () => import("../views/Merchant"),
  },
  {
    path: "/commodity",
    name: "商品管理",
    component: () => import("../views/commodity"),
  },
  {
    path: "/Membership",
    name: "会员升级",
    component: () => import("../views/Membership"),
  },
  {
    path: "/Membership_one",
    name: "会员奖励升级办法",
    component: () => import("../views/Membership_one"),
  },
  {
    path: "/Check",
    name: "会员核验",
    component: () => import("../views/Check"),
  },

  {
    path: "/MyShop",
    name: "我的小店",
    component: () => import("../views/MyShop"),
  },
  {
    path: "/shelves",
    name: "Shelves",
    component: () => import("../views/shelves"),
  },
  {
    path: "/choose",
    name: "Choose",
    component: () => import("../views/choose"),
  },
  {
    path: "/classification",
    name: "Classification",
    component: () => import("../views/classification"),
  },
  {
    // 自营商城分类详情
    path: "/classificationDetail",
    name: "ClassificationDetail",
    component: () => import("../views/classificationDetail"),
  },
  {
    path: "/shoppingcart",
    name: "购物车",
    component: () => import("../views/shoppingcart"),
  },
  {
    path: "/sureorder",
    name: "Sureorder",
    component: () => import("../views/sureorder"),
  },

  // 购物车多商品
  {
    path: "/sureorderMore",
    name: "SureorderMore",
    component: () => import("../views/sureorderMore"),
  },
  {
    path: "/coupon",
    name: "Coupon",
    component: () => import("../views/coupon"),
  },
  {
    path: "/fansManagementNew",
    name: "fansManagementNew",
    component: () => import("../views/fansManagementNew"),
  },
  {
    path: "/fansinfo",
    name: "fansinfo",
    component: () => import("../views/fansinfo"),
  },
  {
    path: "/shoptwo",
    name: "购物车2",
    component: () => import("../views/shoptwo"),
  },

  {
    path: "/WoShopone",
    name: "我的小店壹",
    component: () => import("../views/WoShopone"),
  },
  {
    path: "/fansOrderdatanew",
    name: "粉丝订单壹",
    component: () => import("../views/fansOrderdatanew"),
  },
  {
    path: "/commodder",
    name: "订单管理壹",
    component: () => import("../views/commodder"),
  },
  {
    path: "/shippingAddress",
    name: "ShippingAddress",
    component: () => import("../views/shippingAddress"),
  },
  {
    path: "/c",
    name: "ShippingAddressthree",
    component: () => import("../views/shippingAddressthree"),
  },
  {
    path: "/shippingAddresstwo",
    name: "ShippingAddresstwo",
    component: () => import("../views/shippingAddresstwo"),
  },
  {
    path: "/shippingAddressfive",
    name: "ShippingAddressfive",
    component: () => import("../views/shippingAddressfive"),
  },
  {
    path: "/fansOrderNew",
    name: "fansOrderNew",
    component: () => import("../views/fansOrderNew"),
  },
  {
    path: "/shippingAddress",
    name: "收货地址",
    component: () => import("../views/shippingAddress"),
  },
  {
    path: "/shopAddAddress",
    name: "新增收货地址",
    component: () => import("../views/shopAddAddress"),
  },
  {
    path: "/detailsgoods",
    name: "Detailsgoods",
    component: () => import("../views/detailsgoods"),
  },
  {
    path: "/shareShop",
    name: "shareShop",
    component: () => import("../views/shareShop"),
  },
  {
    path: "/SpellGroup",
    name: "SpellGroup",
    component: () => import("../views/SpellGroup"),
  },

  {
    path: "/shareGoods",
    name: "shareGoods",
    component: () => import("../views/shareGoods"),
  },
  {
    path: "/commoditySpike",
    name: "商品秒杀",
    component: () => import("../views/commoditySpike"),
  },
  {
    path: "/seckillDatil",
    name: "秒杀详情",
    component: () => import("../views/seckillDatil"),
  },
  {
    path: "/Bargain",
    name: "砍价列表",
    component: () => import("../views/Bargain"),
  },
  {
    path: "/bargainDatil",
    name: "砍价详情",
    component: () => import("../views/bargainDatil"),
  },
  {
    path: "/outsell",
    name: "外卖",
    component: () => import("@/views/OutSell"),
  },
  {
    path: "/outsell/catedetail",
    name: "分类详情",
    component: () => import("@/views/OutSell/CateDetail"),
  },
  {
    path: "/productDetails",
    name: "外卖商品详情",
    component: () => import("../views/takeOutFood/productDetails"),
  },
  {
    path: "/storeIndex",
    name: "外卖店铺首页",
    component: () => import("../views/takeOutFood/storeIndex"),
  },
  {
    path: "/settle",
    name: "外卖结算",
    component: () => import("@/views/takeOut/settle"),
  },
  {
    path: "/shoppingDetail",
    name: "外卖店铺详情",
    component: () => import("@/views/takeOut/shoppingDetail"),
  },
  {
    path: "/evaluateStore",
    name: "待评价",
    component: () => import("@/views/takeOut/evaluateStore"),
  },
  {
    path: "/listingDetails",
    name: "房源详情",
    component: () => import("../views/listingDetails/index.vue"),
  },
  {
    path: "/vehicleDetails",
    name: "车辆详情",
    component: () => import("../views/vehicleDetails/index.vue"),
  },
  {
    path: "/AhotelDetails",
    name: "酒店详情",
    component: () => import("../views/hotelDetails/index.vue"),
  },
  {
    path: "/roomDetail",
    name: "酒店房间详情",
    component: () => import("../views/roomDetail/index.vue"),
  },
  {
    path: "/confirmOrder",
    name: "房间下单详情",
    component: () => import("../views/confirmOrder/index.vue"),
  },
  {
    path: "/collection",
    name: "收藏",
    component: () => import("../views/collection/index.vue"),
  },
  {
    path: "/hotelOrder",
    name: "酒店订单",
    component: () => import("../views/hotelOrder/index.vue"),
  },
  {
    path: "/addFrends",
    name: "添加好友",
    component: () => import("../views/addFrends/index.vue"),
  },
  {
    path: "/ahelpCut",
    name: "好友帮砍",
    component: () => import("../views/helpCut/index.vue"),
  },
  {
    path: "/hourRoom",
    name: "钟点房",
    component: () => import("../views/hourRoom/index.vue"),
  },
  {
    path: "/activeOrder",
    name: "商品下单",
    component: () => import("../views/activeOrder/index.vue"),
  },
  {
    path: "/driving",
    name: "驾考",
    component: () => import("@/views/Driving"),
  },
  {
    path: "/calendar",
    name: "万年历",
    component: () => import("@/views/Calendar"),
  },
  {
    path: "/refund",
    name: "退款",
    component: () => import("@/views/refund"),
  },
  {
    path: "/refundDetail",
    name: "退款详情",
    component: () => import("@/views/refundDetail"),
  },
  {
    path: "/mallorderDetail",
    name: "自营商城订单详情",
    component: () => import("@/views/mallorderDetail"),
  },
  {
    path: "/movieDetail",
    name: "电影订单详情",
    component: () => import("@/views/movieDetail"),
  },
  {
    path: "/fitment",
    name: "店铺装修",
    component: () => import("@/views/fitment/fitmentHome"),
  },
  {
    path: "/fitmentModel",
    name: "装修模块",
    component: () => import("@/views/fitment/fitmentModel"),
  },
  {
    path: "/maker",
    name: "创客列表",
    component: () => import("@/views/maker/index"),
  },
  {
    path: "/makerOrder",
    name: "创客商城",
    component: () => import("@/views/maker/order"),
  },
  {
    path: "/fitmentModelHeader",
    name: "装修模块顶部",
    component: () => import("@/views/fitment/fitmentModelHeader"),
  },
  {
    path: "/evaluate",
    name: "商品评价",
    component: () => import("@/views/evaluate"),
  },
  {
    path: "/fitmentHeader",
    name: "装修顶部",
    component: () => import("@/views/fitment/fitmentHeader"),
  },
  {
    path: "/fitmentAddGoods",
    name: "添加商品",
    component: () => import("@/views/fitment/fitmentAddGoods"),
  },
  {
    path: "/fitmentAd",
    name: "装修广告",
    component: () => import("@/views/fitment/fitmentAd"),
  },
  {
    path: "/fitmentText",
    name: "装修文字",
    component: () => import("@/views/fitment/fitmentText"),
  },
  {
    path: "/makervip",
    name: "创客会员",
    component: () => import("@/views/maker/vip"),
  },
  {
    path: "/upOrder",
    name: "创客提交订单",
    component: () => import("@/views/maker/upOrder"),
  },
  {
    path: "/makerStoreDetail",
    name: "创客店铺详情",
    component: () => import("@/views/makerStoreDetail"),
  },
  {
    path: "/makerGoodsDetail",
    name: "创客商品详情",
    component: () => import("@/views/makerGoodsDetail"),
  },
  {
    path: "/makerSearch",
    name: "创客搜索",
    component: () => import("@/views/makerSearch"),
  },
  {
    path: "/makerClassification",
    name: "创客商品分类",
    component: () => import("@/views/makerClassification"),
  },
  {
    path: "/fitmentClassification",
    name: "装修选择分类",
    component: () => import("@/views/fitment/fitmentClassification"),
  },
  {
    path: "/markerGameDetail",
    name: "试玩详情",
    component: () => import("@/views/markerGameDetail"),
  },
  {
    path: "/markerGameDownLoad",
    name: "精彩活动",
    component: () => import("@/views/markerGameDownLoad"),
  },
  {
    path: "/makerOrderRefund",
    name: "创客退款申请",
    component: () => import("@/views/maker/refund"),
  },
  {
    path: "/makerAfterSale",
    name: "创客售后",
    component: () => import("@/views/maker/afterSale"),
  },
  {
    path: "/makerAd",
    name: "创客广告分享页",
    component: () => import("@/views/makerAd"),
  },
  {
    path: "/swiper",
    name: "轮播图",
    component: () => import("@/views/swiper"),
  },
  {
    path: "/makerStoreInvite",
    name: "创客邀请开店",
    component: () => import("@/views/makerStoreInvite"),
  },
  {
    path: "/schoolDetail",
    name: "商学院详情",
    component: () => import("@/views/schoolDetail"),
  },
  {
    path: "/fitmentGoodsCase",
    name: "装修橱窗中商品",
    component: () => import("@/views/fitment/fitmentGoodsCase"),
  },
  {
    path: "/afterSale",
    name: "afterSale",
    component: () => import("@/views/takeOut/afterSale"),
  },
  {
    path: "/cancelReason",
    name: "外卖取消原因",
    component: () => import("@/views/takeOut/cancelReason"),
  },
  {
    path: "/takeOutOrderDetail",
    name: "外卖订单详情",
    component: () => import("@/views/takeOut/orderDetail"),
  },
  {
    path: "/mallEvaluate",
    name: "自营商城评价",
    component: () => import("@/views/mallEvaluate"),
  },
  {
    path: "/mallApplyRefund",
    name: "自营商城退款",
    component: () => import("@/views/mallApplyRefund"),
  },
  {
    path: "/adForm",
    name: "图文表单",
    component: () => import("@/views/adForm"),
  },
  {
    path: "/businessCategory",
    name: "选择经营类目",
    component: () => import("@/views/businessCategory"),
  },
  {
    //课程详情
    path: "/beiDaClassDetail",
    name: "beiDaClassDetail",
    component: () => import("@/views/beiDaClassDetail"),
  },
  {
    //课程视频
    path: "/beiDaClassDetail/classVideo",
    name: "classVideo",
    component: () => import("@/views/beiDaClassDetail/classVideo"),
  },
  {
    path: '/userRemark',
    name: '外卖备注',
    component: () => import('@/views/takeOut/settle/userRemark'),
  },
  {
    path: '/shopList',
    name: '外卖店铺商品列表',
    component: () => import('@/views/takeOut/shopList')
  },
  {
    path: '/search',
    name: '外卖店铺商品列表',
    component: () => import('@/views/takeOut/search')
  },
  {
    path: '/searchResult',
    name: '外卖店铺商品列表',
    component: () => import('@/views/takeOut/searchResult')
  },
  {
    path: '/evaluateShop',
    name: '评价商品',
    component: () => import('@/views/takeOut/evaluateShop')
  },
  {
    path: '/AstartAFight',
    name: "参与拼团",
    component: () => import('../views/start_A_Fight/index.vue')
  },
  {
    path: '/goCollage',
    name: "去拼团",
    component: () => import('../views/goCollage/index.vue')
  },
  {
    path: '/ordersCenter',
    name: "订单中心",
    component: () => import('../views/ordersCenter/index.vue')
  },
  {
    path: '/Setting',
    name: "设置",
    component: () => import('../views/Setting/index.vue')
  },
  {
    path: '/refund',
    name: '退款',
    component: () => import('@/views/refund')
  },
  {
    path: '/makerOrderDetail',
    name: '创客订单详情',
    component: () => import('@/views/maker/orderDetail')
  },
  {
    path: '/makerShoppingCart',
    name: '创客购物车',
    component: () => import('@/views/maker/shoppingCart')
  },
  {
    path: '/fitmentPreview',
    name: '装修预览',
    component: () => import('@/views/fitment/fitmentPreview')
  },
  {
    path: '/fitmentGoods',
    name: '装修商品',
    component: () => import('@/views/fitment/fitmentGoods')

  },
  {
    path: '/fitmentNav',
    name: '装修导航',
    component: () => import('@/views/fitment/fitmentNav')
  },
  {
    path: '/verify',
    name: '验证码',
    component: () => import('@/views/verify')
  },
  {
    path: '/keepOrder',
    name: '家政订单',
    component: () => import('@/views/ordersCenter/keepOrder')
  },
  {
    path: '/logistics',
    name: '物流订单',
    component: () => import('@/views/ordersCenter/logistics')
  },
  {
    path: '/printscreen',
    name: '拉新',
    component: () => import('@/views/printscreen')
  },
  {
    path: '/makerRefundDetail',
    name: '创客退款详情',
    component: () => import('@/views/maker/refundDetail')
  },
  {
    path: '/VIPRule',
    name: '会员规则',
    component: () => import('@/views/maker/rule')
  },
  {
    path: '/makerRefundGoods',
    name: '创客寄回商品',
    component: () => import('@/views/maker/refundGoods')
  },
  {
    path: '/afterSale',
    name: '外卖申请售后',
    component: () => import('@/views/takeOut/afterSale')
  }, {
    path: '/cancelReason',
    name: 'cancelReason',
    component: () => import('@/views/takeOut/cancelReason')
  },
  {
    path: '/customerEvaluation',
    name: '顾客评价',
    component: () => import('@/views/customerEvaluation')
  },
  {
    //央视拍摄
    path: "/cctVideo",
    name: "cctVideo",
    component: () => import("@/views/cctVideo"),
  },
  {
    path: "/detailsGoodsEvaluate",
    name: "detailsGoodsEvaluate",
    component: () => import("@/views/detailsGoodsEvaluate"),
  },
  {
    //红包雨
    path: "/redPackageRainMain",
    name: "redPackageRainMain",
    component: () => import("@/views/redPackageRain"),
  },
  {
    //红包雨分享
    path: "/redPackageRainShare",
    name: "redPackageRainShare",
    component: () => import("@/views/redPackageRain/share"),
  },
  {
    //红包雨详情
    path: "/redPackageRain",
    name: "redPackageRain",
    component: () => import("@/views/redPackageRain/detail"),
  },
  {
    //三方sdk
    path: "/thirdSDK",
    name: "thirdSDK",
    component: () => import("@/views/thirdSDK"),
  },
  {
    //分享页面
    path: "/sharePage",
    name: "sharePage",
    component: () => import("@/views/sharePage"),
  },
  {
    //卡券分享
    path: "/shareVoucher",
    name: "shareVoucher",
    component: () => import("@/views/shareVoucher"),
  },
  {
    //会员等级
    path: "/memberOrder",
    name: "memberOrder",
    component: () => import("@/views/memberOrder"),
  },
  {
    //走路赚钱
    path: "/makeMoney",
    name: "makeMoney",
    component: () => import("@/views/makeMoney"),
  },
  {
    //邀请注册
    path: "/invitationRegister",
    name: "invitationRegister",
    component: () => import("@/views/invitationRegister"),
  },
  {
    //邀请注册页面
    path: "/invitationRegisterpage",
    name: "invitationRegisterpage",
    component: () => import("@/views/invitationRegisterpage"),
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('@/views/OrderDetail')
  },
  {
    path: '/zirefund',
    name: 'zirefund',
    component: () => import('@/views/OrderDetail/refund')
  },
  {
    path: '/checkLogistics',
    name: '查看物流',
    component: () => import('@/views/OrderDetail/checkLogistics')
  },
  {
    path: '/dealSuccess',
    name: '交易成功',
    component: () => import('@/views/OrderDetail/dealSuccess')
  },
  {
    path: '/commentOrder',
    name: '发表评价',
    component: () => import('@/views/OrderDetail/commentOrder')
  },
  {
    path: '/birthday',
    name: '生日花语',
    component: () => import('@/views/birthdayLibrary/birthday')
  },
  {
    path: '/library',
    name: '图书电商',
    component: () => import('@/views/birthdayLibrary/library')
  },
  {
    path: '/libraryDetail',
    name: '图书电商详情',
    component: () => import('@/views/birthdayLibrary/library/libraryDetail')
  },
  {
    path: '/news',
    name: '新闻列表',
    component: () => import('@/views/news/index')
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('@/views/login/index')
  },
  {
    path: '/phone',
    name: '手机号登录',
    component: () => import('@/views/login/phone')
  },
  {
    path: '/password',
    name: '验证码登录',
    component: () => import('@/views/login/password')
  },
  {
    path: '/forgetpassword',
    name: '忘记密码',
    component: () => import('@/views/login/forgetpassword')
  },
  {
    path: '/register',
    name: '注册',
    component: () => import('@/views/login/register')
  },
  // 分享
  {
    path: '/loginShare',
    name: '登录',
    component: () => import('@/views/login/loginShare')
  },
  // 鬼市商品详情
  {
    path: '/milimallDetail',
    name: '商品详情',
    component: () => import('../views/milimallDetail')
  },
  {
    path: '/miliOrder',
    name: '确认订单',
    component: () => import('../views/milimallDetail/miliOrder')
  },
  {
    path: '/miliOrderInfo',
    name: '订单详情',
    component: () => import('../views/milimallDetail/miliOrderInfo')
  },
  {
    path: "/miliShop",
    name: "miliShop",
    component: () => import("../views/milimallDetail/miliShop"),
  },
  // 红尘坊
  {
    path: '/flMallCommodityDetail',
    name: 'flMallCommodityDetail',
    component: () => import('@/views/flMall/flMallCommodityDetail')
  },
  {
    path: '/flMallStoreDetail',
    name: 'flMallStoreDetail',
    component: () => import('@/views/flMall/flMallStoreDetail')
  },
  {
    path: '/flMallCart',
    name: 'flMallCart',
    component: () => import('@/views/flMall/flMallCart')
  },
  {
    path: '/flzhifu',
    name: 'flzhifu',
    component: () => import('@/views/flMall/flzhifu')
  },
  {
    path: '/refundInfo',
    name: 'refundInfo',
    component: () => import('@/views/flMall/refundInfo')
  },
  //订单详情页（订单id）
  {
    path: '/orderIdDetail',
    name: 'orderIdDetail',
    component: () => import('@/views/orderIdDetail')
  },
  //确认收货
  {
    path: '/confirmReceipt',
    name: 'confirmReceipt',
    component: () => import('@/views/confirmReceipt')
  },
  //申请换货
  {
    path: '/applyForChange',
    name: 'applyForChange',
    component: () => import('@/views/applyForChange')
  },
  //换货详情
  {
    path: '/changeGoodsDetail',
    name: 'changeGoodsDetail',
    component: () => import('@/views/changeGoodsDetail')
  },
  //评价页面
  {
    path: '/goodsEvaluate',
    name: 'goodsEvaluate',
    component: () => import('@/views/goodsEvaluate')
  },
  //填写物流
  {
    path: '/writeLogistics',
    name: 'writeLogistics',
    component: () => import('@/views/writeLogistics')
  },
  {
    path: '/hongbao',
    name: 'hongbao',
    component: () => import('@/views/hongbao')
  },
  //侠客集市订单详情
  {
    path: '/yundian/orderIdDetail',
    name: 'yundianOrderIdDetail',
    component: () => import('@/views/yundian/orderIdDetail')
  },
  //侠客集市换货详情
  {
    path: '/yundian/changeGoodsDetail',
    name: 'changeGoodsDetail',
    component: () => import('@/views/yundian/changeGoodsDetail')
  },
  //侠客集市申请换货
  {
    path: '/yundian/applyForChange',
    name: 'applyForChange',
    component: () => import('@/views/yundian/applyForChange')
  },
  //侠客集市商品评价
  {
    path: '/yundian/goodsEvaluate',
    name: 'goodsEvaluate',
    component: () => import('@/views/yundian/goodsEvaluate')
  },
  //侠客集市确认收货
  {
    path: '/yundian/confirmReceipt',
    name: 'confirmReceipt',
    component: () => import('@/views/yundian/confirmReceipt')
  },
  // 侠客集市商品详情
  {
    path: '/ydMallCommodityDetail',
    name: 'ydMallCommodityDetail',
    component: () => import('@/views/cloudShop/ydMallCommodityDetail')
  },
  // 侠客集市店铺详情
  {
    path: '/ydMallStoreDetail',
    name: 'ydMallStoreDetail',
    component: () => import('@/views/cloudShop/ydMallStoreDetail')
  },
  // 侠客集市支付
  {
    path: '/ydzhifu',
    name: 'ydzhifu',
    component: () => import('@/views/cloudShop/ydzhifu')
  },
  // 侠客集市购物车
  {
    path: '/ydMallCart',
    name: 'ydMallCart',
    component: () => import('@/views/cloudShop/ydMallCart')
  },
  {
    path: '/ydRefundInfo',
    name: 'ydRefundInfo',
    component: () => import('@/views/cloudShop/ydRefundInfo')
  },

  // 靓号管理(首页)
  {
    path: '/lianghao',
    name: 'lianghao',
    component: () => import('@/views/lianghao/shouye')
  },
  // 靓号管理(交易记录)

  {
    path: '/lianghaoPay',
    name: 'lianghaoPay',
    component: () => import('@/views/lianghao/shouye/payMoney')
  },
  // 靓号管理(支付)

  {
    path: '/lianghaoinfo',
    name: 'lianghaoinfo',
    component: () => import('@/views/lianghao/shouye/info')
  },
  // 修改收货地址（未发货）
  {
    path: '/editAddress',
    name: 'editAddress',
    component: () => import('@/views/addAddress/editAddress')
  },
  // 新增收货地址（未发货）
  {
    path: '/addAddress',
    name: 'addAddress',
    component: () => import('@/views/addAddress/addAddress')
  },
  // 申请售后（红尘坊和侠客集市）
  {
    path: '/selectService',
    name: 'selectService',
    component: () => import('@/views/applyService/selectService')
  },
  // 申请退货/退款（红尘坊和侠客集市）
  {
    path: '/returnMoneyOrGoods',
    name: 'returnMoneyOrGoods',
    component: () => import('@/views/applyService/returnMoneyOrGoods')
  },
  // 商品评价详情（红尘坊和侠客集市）
  {
    path: '/goodsEvaluateDetail',
    name: 'goodsEvaluateDetail',
    component: () => import('@/views/goodsEvaluateDetail/index')
  },
  // 金叶子现金充值
  {
    path: '/payRedRiceCode',
    name: 'payRedRiceCode',
    component: () => import('@/views/payRedRiceCode/index')
  },
  {
    path: '/ticketCenter',
    name: 'ticketCenter',
    component: () => import('@/views/ticketCenter/index')
  },
  {
    path: '/discount',
    name: 'discount',
    component: () => import('@/views/discount/index')
  },
  // 平台数据（陈）
  {
    path: '/platformData',
    name: 'platformData',
    component: () => import('@/views/platformData/index')
  },
  // 数据统计PC=》登录（陈）
  {
    path: '/dataStatisticsLogin',
    name: 'dataStatisticsLogin',
    component: () => import('@/views/dataStatistics/login')
  },
  // 数据统计PC（陈）
  {
    path: '/dataStatistics',
    name: 'dataStatistics',
    component: () => import('@/views/dataStatistics/index')
  },
  // 罗马集市订单详情
  {
    path: '/romeMarketOrderDetail',
    name: 'romeMarketOrderDetail',
    component: () => import('@/views/romeMarket/orderDetail')
  },
  // 罗马集市
  {
    path: '/romeMarketComodity',
    name: 'romeMarketComodity',
    component: () => import('@/views/romeMarketComodity/index')
  },
  {
    path: '/slyderAdventures',
    name: 'slyderAdventures',
    component: () => import('@/views/marketingCampaign/slyderAdventures/index')
  },
  {
    path: '/slyderAdventuresRecords',
    name: 'slyderAdventuresRecords',
    component: () => import('@/views/marketingCampaign/slyderAdventures/slyderAdventuresRecords')
  },
  {
    path: '/shakeIfOff',
    name: 'shakeIfOff',
    component: () => import('@/views/marketingCampaign/shakeIfOff/index')
  },
  {
    path: '/shakeIfOffRecords',
    name: 'shakeIfOffRecords',
    component: () => import('@/views/marketingCampaign/shakeIfOff/shakeIfOffRecords')
  },
  {
    path: '/southMarket',
    name: 'southMarket',
    component: () => import('@/views/marketingCampaign/southMarket/index')
  },
  {
    path: '/southShuoming',
    name: 'southShuoming',
    component: () => import('@/views/marketingCampaign/southMarket/southShuoming')
  },
  {
    path: '/slyderAdventuresAddAddress',
    name: 'slyderAdventuresAddAddress',
    component: () => import('@/views/marketingCampaign/slyderAdventures/slyderAdventuresAddAddress')
  },
  {
    path: '/travel',
    name: 'travel',
    component: () => import('@/views/marketingCampaign/travel/index')
  },
  {
    path: '/travelStoreDetail',
    name: 'travelStoreDetail',
    component: () => import('@/views/marketingCampaign/travel/travelStoreDetail')
  },
  // 市井文旅服务订单详情
  {
    path: '/culturalTourismOrderDetail',
    name: 'culturalTourismOrderDetail',
    component: () => import('@/views/culturalTourismOrder/index')
  },
  // 南市订单详情
  {
    path: '/southMarketOrderDetail',
    name: 'southMarketOrderDetail',
    component: () => import('@/views/southMarketOrder/index')
  },
  {
    path: '/ysGuan',
    name: 'ysGuan',
    component: () => import('@/views/marketingCampaign/ysGuan/index')
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach(function (to, from, next) {
  store.commit("addRouter", to) // 如果要去的页面已经保存在历史记录里，那么就不在添加to这条记录
  next()
})

export default router
