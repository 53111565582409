import axios from "axios";
import store from "@/store";
// import Vue from 'vue'
// const vm = new Vue()
// axios.defaults.timeout = 30000; //响应时间
axios.defaults.timeout = 120000; //响应时间
axios.defaults.headers.post["Content-Type"] =
  // "application/x-www-form-urlencoded;charset=UTF-8"; //配置请求头
  "application/json";

//axios.defaults.baseURL =    //'/mallapp';   //配置接口地址

// axios.defaults.baseURL = '/mallapp';   //配置接口地址

// axios.defaults.baseURL = 'http://121.36.73.162:9999';   //配置接口地址
// axios.defaults.withCredentials = true

// let thirdSession = window.sessionStorage.getItem('third-session')
// if(!thirdSession) {
//   thirdSession = store.state.session
// }

// let thirdSession = store.state.session
//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  (config) => {
    //在发送请求之前做某件事
    // if(config.method  === 'post'){
    //     config.data = qs.stringify(config.data);
    // }
    // store.state.LOADING=true
    // load.LOADING = true
    // vm.$GeShowLoading()
    let thirdSession = window.sessionStorage.getItem("third-session");
    if (!thirdSession) {
      thirdSession = store.state.session;
    }

    config.headers["client-type"] = "APP";
    config.headers["third-session"] = thirdSession;
    // config.headers["token"] = store.state.token;
    // config.headers["token"] = thirdSession;
    // 后面加的
    config.headers["tenant-id"] = "1";
    return config;
  },
  (error) => {
    // vm.$GeHideLoading()
    return Promise.reject(error);
  }
);

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  (res) => {
    if (res.data.code === 60001) {
      againLogin();
    }
    if (!res.data.success) {
      return Promise.resolve(res);
    }

    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//返回一个Promise(发送post请求)
export function fetchPost(url, params, param) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, { params: param })
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}
////返回一个Promise(发送get请求)
export function fetchGet(url, param) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: param })
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}
////返回一个Promise(发送delete请求)
export function fetchDelete(url) {
  return new Promise((resolve, reject) => {
    axios
      .delete(url)
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}
// （发送修改put请求）
export function fetchPut(url, param) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, param)
      .then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
}
export function getPhoneType() {
  let u = navigator.userAgent;
  let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  if (isIOS) {
    return "isIOS";
  }
  if (isAndroid) {
    return "isAndroid";
  }
}
export function againLogin() {
  if (getPhoneType() == "isIOS") {
    window.webkit.messageHandlers.close.postMessage("login");
  } else {
    window.android.login();
  }
}

export default {
  fetchPost,
  fetchGet,
  fetchDelete,
  fetchPut,
};
