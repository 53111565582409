export default {
  namespaced: true,
  state: {
    shopCar: [],
    distance: [], // 商家经纬度
    transportUserPrice: '',
    userRemark: '',
    lotLat: [], // 获取用户地址经纬度
    im: '',
    sellerAdress: '', // 卖家地址
    transportStartPrice: '', // 起送价
    fullReduces: [], // 满减券
    userAddress: {}, // 收货地址
    deliveryhTime: '', // 展示配送时间
    expectDeliveTime: '', // 提交预计送达时间
    checked: '3', // 支付方式
    disabled: true, // 结算按钮置灰
    businussTime: [], // 商家营业时间
    commentOrderInfo: {}
  },
  getters: {
    goodsIds (state)  {
      const ids = []
      state.shopCar.forEach(item => {
        ids.push(item.goodsId)
      })
      return ids
    },

    idArray (state) {
      const arr = []
      state.shopCar.forEach(item => {
        const index = arr.findIndex(item2 => item.goodsId == item2.goodsId)
        if (index === -1) {
          arr.push({
            goodsId: item.goodsId,
            quantity: item.quantity
          })
        } else {
          arr[index].quantity = arr[index].quantity + item.quantity
        }
      })
      const newArr = []
      arr.forEach(item => newArr.push(`${item.goodsId}@${item.quantity}`))
      return newArr
    },

    noDistribution (state) {
      let peisong = false
      if (state.shopCar.length) {
        peisong = state.shopCar.every(item => item.noSingle == '1')
      }
      return peisong
    },

    // 商品总价格 总商品原价 总商品现价
    shopPrice (state)  {
      let originalPrice = 0
      let price = 0
      let shopPrice  = []
      state.shopCar.forEach(item => {
        const itemoriginalPrice = item.originalPrice * item.quantity
        const itemPrice = item.price * item.quantity
        originalPrice += itemoriginalPrice
        price += itemPrice
      })
      shopPrice.push(originalPrice.toFixed(2))
      shopPrice.push(price.toFixed(2))
      return shopPrice
    },

    // 商品图片
    shopImg: (state) => {
      const shopImgs = []
      state.shopCar.forEach(item => {
        shopImgs.push(item.goodsImages)
      })
      return shopImgs
    },

    // 商品规格
    allGoods: (state) => {
      const allGoods = []
      state.shopCar.forEach(item => {
        if (!item.allGoods.length) {
          allGoods.push(`${item.goodsId}@无规格@${item.quantity}@${item.goodsName}`)
        } else {
          let standardId = ''
          item.allGoods.forEach((item2, index2) => {
            if (index2 != item.allGoods.length-1) {
              standardId = standardId + `${item2.standardId}:${item2.id}_`
            } else {
              standardId = standardId + `${item2.standardId}:${item2.id}`
            }
          })
          allGoods.push(`${item.goodsId}@${standardId}@${item.quantity}@${item.goodsName}`)
        }
      })
      return allGoods
    }
  },

  mutations: {
    set_commentOrderInfo (state, obj) {
      state.commentOrderInfo = JSON.parse(JSON.stringify(obj))
    },

    add_shop (state, params) {
      state.shopCar.push(params)
    },

    //删除商品
    delete_shop (state, params) {
      const newSpecificationArr = []
      params.specificationArr.forEach(item => newSpecificationArr.push(item.id))
      state.shopCar.forEach((item, index) => {
        const ids = []
        if (params.goodsId == item.goodsId) {
          item.allGoods.forEach(item2 => ids.push(item2.id))
        }
        const trueFalse = newSpecificationArr.every(item3 => ids.includes(item3))
        if (trueFalse) {
          state.shopCar.splice(index, 1)
        }
      })
    },

    // 改变数量
    change_quantity (state, params) {
      const newSpecificationArr = []
      params.specificationArr.forEach(item => newSpecificationArr.push(item.id))
      state.shopCar.forEach((item, index) => {
        const ids = []
        if (params.goodsId != item.goodsId) {
          return
        }
        item.allGoods.forEach(item2 => {
          ids.push(item2.id)
        })
        const trueFalse = newSpecificationArr.every(item => ids.includes(item))
        if (trueFalse) {
          state.shopCar[index].quantity = params.guigeStepper
        }
      })
    },

    set_shopCar (state, shopCar) {
      state.shopCar = JSON.parse(JSON.stringify(shopCar))
    },

    // 添加经纬度
    add_distance (state, params) {
      const lot_lat = JSON.parse(JSON.stringify(params))
      state.distance[0] = lot_lat[0]
      state.distance[1] = lot_lat[1]
    },

    user_remark (state, params) {
      state.userRemark = params
    },

    transport_User_Price (state, params) {
      let param = 0
      if (params) {
        param = params.split(',')[0]
      } else {
        param = 0
      }
      state.transportUserPrice = param
    },

    lot_lat (state, params) {
      state.lotLat[0] = params.lot
      state.lotLat[1] = params.lat
    },

    detail_add_shop (state, params) {
      state.shopCar.push(params)
    },

    detail_change_quantity (state, params) {
      console.log(params, '数量')
      const index = state.shopCar.findIndex(item => item.goodsId == params.goodsId)
      if (index === -1) {
        state.shopCar.push(params)
      } else {
        state.shopCar[index].quantity = params.quantity
      }
    },

    detelt_detail_shop (state, goodsId) {
      const index = state.shopCar.findIndex(item => item.goodsId == goodsId)
      state.shopCar.splice(index, 1)
    },

    get_im (state, params) {
      state.im = params
    },

    empty_shop_car (state) {
      state.shopCar = []
    },

    get_sellerAddress (state, params) {
      state.sellerAdress = params
    },

    get_transportStartPrice (state, params) {
      state.transportStartPrice = params
    },

    get_fullReduces (state, params) {
      state.fullReduces = JSON.parse(JSON.stringify(params))
    },

    get_userAddress (state, params) {
      state.userAddress = JSON.parse(JSON.stringify(params))
    },

    get_deliveryhTime (state, param) {
      state.deliveryhTime = param
    },

    get_expectDeliveTime (state, param) {
      state.expectDeliveTime = param
    },

    get_checked (state, param) {
      state.checked = param
    },

    set_disabled (state, param) {
      state.disabled = param
    },

    set_businussTime (state, params) {
      state.businussTime = JSON.parse(JSON.stringify(params))
    }
  }
}