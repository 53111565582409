import { user } from "../api/userInfo/index"
import { aliPay, wxPay } from "../api/hotel/index";
import { cancelColl } from "../api/collection";
import { Toast } from 'vant'

import { delOrder } from '../api/deleteOrder/index'

export function roadNav(longitude, latitude, locationName) {
    // import {stro}
    if (window.webkit) {
        window.webkit.messageHandlers.location.postMessage(
            JSON.stringify({
                longitude: longitude,
                latitude: latitude,
                name: locationName
            })
        )
    } else {
        window.android.openNavigation(longitude, latitude, locationName)
    }
}
export function setRem() {
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
}
export function goBack() {
    try {




        if (this.$store.state.routerArr[0].fullPath === this.$route.fullPath || !this.$store.state.routerArr.length) {
            try {
                window.webkit.messageHandlers.close.postMessage("up");
            } catch {
                window.android.close();

            }
            return
        }
        this.$router.back()

    } catch (error) {
        //
    }


}
export function back() {
    try {
        window.webkit.messageHandlers.close.postMessage("up");
    } catch (error) {
        window.android.close();
    }
}

function share(data) {
    // let u = navigator.userAgent;
    // let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
    // let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    // if (isIOS) {
    //   window.webkit.messageHandlers.share.postMessage(JSON.stringify(data));
    // }
    // if (isAndroid) {



    //   // window.android.share(data,'3')
    //   window.android.share(
    //     data.platformId,
    //     "3",
    //     data.title,
    //     data.content,
    //     data.openUrl,
    //     data.picUrl
    //   );
    // }
    try {
        window.webkit.messageHandlers.shareOther.postMessage(data);
    } catch {

        window.android.shareOther(JSON.stringify(data));
    }
}
export function shareInfo(data) {

    data.openUrl = data.openUrl || (window.location.href)
    share(data)

}
export function getUser() {
    try {

        return new Promise((resolve) => {
            user().then(res => {
                this.$store.commit('setUserInfo', res.data.data)
                resolve()
            })

        })

    } catch {
        //
    }
}
export function fetchTime(start, time) {

    let timeNum = (Date.now() - (new Date((start).replace(/-/ig, '/')).getTime())) / 1000

    let time2 = parseInt(time * 60 - timeNum)

    if (time2 < 0) {

        return -1

    }

    if (time2 / 60 < 1) {

        return (time2 % 60) + "秒"

    } else {

        return parseInt(time2 / 60) + '分' + (time2 % 60) + "秒"

    }
}
export function hotelPay(data, type) {

    if (type === 2) {

        submitAlipay(data)

    } else {

        getWxPay(data)

    }


}
// 请求支付宝支付
const submitAlipay = (form) => {
    aliPay(form).then((res) => {
        if (res) {

            let u = navigator.userAgent;
            let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            try {
                if (isIOS) {
                    window.webkit.messageHandlers.alipay.postMessage(res.data)
                    this.data = 222
                }

                if (isAndroid) {
                    window.android.alipay(res.data);
                }
            } catch (error) {
                //
                this.$Toast("下单失败");

            }
        }
    });
}
//微信支付
const getWxPay = (data) => {

    wxPay({ orderId: data, tradeType: "APP" }).then((res) => {

        this.data = res.data

        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        try {

            if (isIOS) {

                window.webkit.messageHandlers.wechatPay.postMessage(
                    JSON.stringify(res.data.data)
                );
            }
            if (isAndroid) {
                window.android.wxpay(
                    res.data.data.appId,
                    res.data.data.partnerId,
                    res.data.data.prepayId,
                    res.data.data.nonceStr,
                    res.data.data.timeStamp,
                    res.data.data.packageValue,
                    res.data.data.sign
                );
            }
        } catch (error) {
            //
            this.$Toast("下单失败");
        }

    });
}


export function isIOS() {
    let u = navigator.userAgent;
    let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    return isIos
}

export function openPay(data, type) {
    // 微信支付           
    try {
        if (type === '1') {
            if (isIOS()) {
                window.webkit.messageHandlers.wechatPay.postMessage(JSON.stringify(data.data.data))
            } else {
                window.android.wxpay(
                    data.data.data.appId,
                    data.data.data.partnerId,
                    data.data.data.prepayId,
                    data.data.data.nonceStr,
                    data.data.data.timeStamp,
                    data.data.data.packageValue,
                    data.data.data.sign
                );
            }
        } else {
            try {
                window.webkit.messageHandlers.alipay.postMessage(data.data)
            } catch {
                window.android.alipay(data.data)
            }
            /*  if (isIOS()) {
                 window.webkit.messageHandlers.alipay.postMessage(data.data)
             } else {
                 window.android.alipay(JSON.stringify(data.data))
             } */
        }
    } catch (err) {
        //
    }

}

export function fuli_yundian_pay (data, type) {
    // 微信支付           
    try {
        if (type === '1') {
            if (isIOS()) {
                window.webkit.messageHandlers.wechatPay.postMessage(JSON.stringify(data.data.data))
            } else {
                window.android.wxpay(
                    data.data.data.appId,
                    data.data.data.partnerId,
                    data.data.data.prepayId,
                    data.data.data.nonceStr,
                    data.data.data.timeStamp,
                    data.data.data.packageValue,
                    data.data.data.sign
                );
            }
        } else {
            try {
                window.webkit.messageHandlers.alipay.postMessage(data.data)
            } catch {
                window.android.alipay(data.data)
            }
        }
    } catch (err) {
        //
    }

}

// export function downloadAPP () {
//     let appInfo = navigator.userAgent
//     if (appInfo.indexOf('QQTheme') != -1) {
//         window.location = ''
//         return
//     }
// }

// url, original host/pathPrefix
export function openApp(url, original) {
    // this.openUrl='zeekr://hostt/'+ (window.location.href).split('/#/')[1]
    console.log('打开app');
    let appInfo = navigator.userAgent
    // if( ![ typeof window , typeof document].includes('underfined') ){ 
    //      Toast.fail("请在复制链接在默认浏览器中打开")
    //      return
    // }
    if (appInfo.indexOf('MicroMessenger') != -1) {
        Toast.fail("请点击右上角按钮在在浏览器中打开")
        return
    }
    if (appInfo.indexOf('QQTheme') != -1) {
        Toast.fail("请在复制链接在默认浏览器中打开")
        return
    }
    if (appInfo.indexOf('万民') < 0) {
        if (original) {

            this.$toast('没有链接')
            //  跳转原生页面
            try {
                if (!isIOS()) {
                    throw ('andriod')
                }
                // window.location = 'YD20211111://'+ url
                if (url.indexOf('://') == -1) {
                    this.$sendData('mzjumpapp20220519://' + url)
                } else {
                    this.$sendData(url)
                }
                this.$showAlert()
                return
            } catch {

                //
            }
            try {
                // window.location='makermall://'+url 
                if (url.indexOf('://') == -1) {
                    this.$sendData('makermall://' + url)
                } else {
                    this.$sendData(url)
                }
                this.$showAlert()
            } catch {
                //
            }

        } else {
            this.$toast('跳转')
            //  跳转h5
            try {
                if (!isIOS()) throw ('andriod')

                // window.location = 'YD20210601://'+ (url ??(window.location.href).split('/#/')[1]) 
                this.$sendData('mzjumph520220519://' + (url || (window.location.href).split('/#/')[1]))
                this.$showAlert()
                return
            } catch {
                //
            }

            try {
                // window.location='zeekr://'+ (url ??(window.location.href).split('/#/')[1])
                if (url) {
                    this.$sendData('zeekr://' + url)
                } else {
                    this.$sendData('zeekr://' + (window.location.href).split('/#/')[1])

                }
                this.$showAlert()
            } catch {
                //
            }

        }
    }

}
export const cCT = (data) => {

    return new Promise(function (resolve, reject) {

        cancelColl(data).then(res => {

            if (res.data.data) {

                Toast.success('取消成功')

                resolve(res)

            } else {

                Toast.fail('取消失败')

                reject(res)

            }
        })

    })
}

export const deleteOrder = (data) => {

    return new Promise((resolve, reject) => {

        delOrder(data).then(res => {

            if (res.data.code === 0) {

                Toast.success('删除成功')

                resolve(res)

            } else {

                Toast.fail('删除失败')

                reject(res)

            }

        })

    })
}

export const jump = (type, id) => {

    try {

        window.webkit.messageHandlers.jump.postMessage(JSON.stringify({ type: type, id: id }))

    } catch {


        window.android.goRecruit(type, id)
    }



}

export const switchTime = (end, range) => {

    return new Promise(resolve => {
        let time = range - (Date.now() - (new Date(end.replace(/-/ig, '/')).getTime()))
        if (time > 0) {
            resolve(time)
        } else {

            resolve(0)
        }


    })


}

function FloatMul(arg1, arg2) {
    var m = 0,
      s1 = arg1.toString(),//将第一个数据转换成字符出类型
      s2 = arg2.toString();//将第二个数据转换成字符出类型
    try {
      m += s1.split(".")[1].length;//截取数据的小数部分，得到小数位数
    } catch (e) { 
      console.log()
    }
    try {
      m += s2.split(".")[1].length;//截取数据的小数部分，得到小数位数
      //将两个数据的小数位数长度相加
    } catch (e) {
      console.log()
    }
    var result = (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) / Math.pow(10, m);//将两个数扩大对应的小数位数倍转换成数字类型在相乘，乘完再除于对应的扩大倍数得到最终结果
    return isNaN(result) ? 0 : result;
}

export function accountAdd (arg1, arg2) {
    let r1, r2, m;
    try {
        r1 = arg1.toString().split(".")[1].length;//将第一个数据转换成字符出类型，截取数据的小数部分，得到小数位数
    } catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;//将第一个数据转换成字符出类型，截取数据的小数部分，得到小数位数
    } catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));//取出得到的最长位数，将10扩大最长位数倍
    return (FloatMul(arg1, m) - FloatMul(arg2, m)) / m;
}

export const contact = (id) => {
    if (!id) return
    try {

        window.webkit.messageHandlers.contact.postMessage(JSON.stringify({ id: id }))
    } catch {

        window.android.contact(id)

    }
}

export default {
    roadNav,
    goBack,
    setRem,
    shareInfo,
    getUser,
    fetchTime,
    hotelPay,
    isIOS,
    openPay, //调用支付方法
    openApp, //浏览器跳app方法
    back, //返回上一页方法
    cCT, // 取消收藏方法,
    deleteOrder,
    jump, // 跳转方法
    contact //IM跳转
}